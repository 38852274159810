const initState = {
  paramsTMP: [],
  loadingParams: false,
  paramsError: '',
};
export default (PARAMSREDUCER = initState, action) => {
  switch (action.type) {
    case 'ALLPARAMS':
      return {
        ...PARAMSREDUCER,
        paramsTMP: action.payload,
        loadingParams: false,
        paramsError: '',
      };

    case 'LOADINGPARAMS':
      return {
        ...PARAMSREDUCER,
        loadingParams: '',
        paramsError: true,
      };

    case 'ADSERROR':
      return {
        ...PARAMSREDUCER,
        loadingParams: false,
        paramsError: action.payload,
      };

    default:
      return PARAMSREDUCER;
  }
};
