const initState = {
  postTMP: [],
  loadingPost: false,
  postByTitle: [],
  postError: '',
};
export default (POSTREDUCER = initState, action) => {
  switch (action.type) {
    case 'ALLPOST':
      return {
        ...POSTREDUCER,
        postTMP: action.payload,
        loadingPost: false,
        postError: '',
      };
    case 'POSTBYTITLE':
      return {
        ...POSTREDUCER,
        postByTitle: action.payload,
        loadingPost: false,
        postError: '',
      };

    case 'LOADINGPOST':
      return {
        ...POSTREDUCER,
        postError: '',
        loadingPost: true,
        postError: '',
      };

    case 'POSTERROR':
      return {
        ...POSTREDUCER,
        loadingPost: false,
        postError: action.payload,
      };

    default:
      return POSTREDUCER;
  }
};
