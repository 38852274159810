import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const Script = () => {
  // const [params, setParams] = useState();
  // const paramsData = useSelector((state) => state.PARAMSREDUCER.paramsTMP);
  let script = '';
  let script2 = '';
  // useEffect(() => {
  //   if (paramsData) {
  //     gtagScript(paramsData).then(() => {

  //     });
  //   }
  // }, [paramsData]);
  const gtagScript = async (data) => {
    return new Promise((resolve, reject) => {});
  };

  useEffect(() => {
    const src = `https://www.googletagmanager.com/gtag/js?id=UA-246587826-1`;
    script = document.createElement('script');
    script2 = document.createElement('script');

    script2.text = `window.dataLayer = window.dataLayer || [];

  function gtag(){dataLayer.push(arguments);}

  gtag('js', new Date());

  gtag('config', 'UA-246587826-1');`;
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
    document.head.appendChild(script2);
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  }, []);

  return null;
};
