const initState = {
  categoryTMP: [],
  filterCategory: [],
  loadingCategory: false,
};
export default (CATEGORYREDUCER = initState, action) => {
  switch (action.type) {
    case 'ADDCATEGORY':
      return {
        ...CATEGORYREDUCER,
        categoryTMP: action.payload,
        loadingCategory: false,
      };
    case 'FILTERCATEGORY':
      return {
        ...CATEGORYREDUCER,
        filterCategory: action.payload,
        loadingCategory: false,
      };
    case 'LOADING':
      return {
        ...CATEGORYREDUCER,
        loadingCategory: true,
      };

    default:
      return CATEGORYREDUCER;
  }
};
