const initState = {
  commandeTMP: '',
  orderTMP: false,
  productTMP: [],
  checkoutTMP: false,
  loadingOrder: false,
  errorOrder: '',
};
export default (ORDERREDUCER = initState, action) => {
  switch (action.type) {
    case 'CREATECOMMAND':
      localStorage.setItem('commandCreated', JSON.stringify(action.payload));

      return {
        ...ORDERREDUCER,
        commandeTMP: action.payload,
        loadingOrder: false,
        errorOrder: '',
      };
    case 'SETORDER':
      localStorage.removeItem('commandCreated');
      localStorage.removeItem('commande');

      return {
        ...ORDERREDUCER,
        orderTMP: true,
        productTMP: '',
        checkoutTMP: false,
        commandeTMP: '',
        loadingOrder: false,
        errorOrder: '',
      };
    case 'GETCOMMANDCREATED':
      return {
        ...ORDERREDUCER,
        checkoutTMP: true,
        commandeTMP: JSON.parse(localStorage.getItem('commandCreated')),
        loadingOrder: false,
        errorOrder: '',
      };
    case 'GETCOMMAND':
      return {
        ...ORDERREDUCER,
        checkoutTMP: true,
        productTMP: JSON.parse(localStorage.getItem('commande')),
        loadingOrder: false,
        errorOrder: '',
      };
    case 'CHECKOUT':
      localStorage.setItem('commande', JSON.stringify(action.payload));
      return {
        ...ORDERREDUCER,
        checkoutTMP: true,
        productTMP: action.payload,
        loadingOrder: false,
        errorOrder: '',
      };
    case 'RESETCOMMAND':
      localStorage.removeItem('commandCreated');
      localStorage.removeItem('commande');
      return {
        ...ORDERREDUCER,
        commandeTMP: '',
        orderTMP: false,
        productTMP: [],
        checkoutTMP: false,
        loadingOrder: false,
        errorOrder: '',
      };

    case 'LOADINGORDER':
      return {
        ...ORDERREDUCER,
        errorOrder: '',
        loadingOrder: true,
      };

    case 'ERRORORDER':
      return {
        ...ORDERREDUCER,
        loadingOrder: false,
        errorOrder: action.payload,
      };

    default:
      return ORDERREDUCER;
  }
};
