import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers/Index.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
root.render(
  <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        http-equiv="Strict-Transport-Security"
        content="max-age=31536000; includeSubDomains"
      />

      <title>{process.env.REACT_APP_webSiteName.substring(8)}</title>
      <meta
        name="description"
        content={process.env.REACT_APP_webSiteDescription}
      />
      <meta name="title" content={process.env.REACT_APP_webSiteName} />
      <meta name="keywords" content={process.env.REACT_APP_webSiteKeywords} />
      <meta property="og:type" content="website" />

      <link rel="canonical" href={window.location.href} />
    </Helmet>

    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>
);
