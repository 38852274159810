import { combineReducers } from 'redux';
import CATEGORYREDUCER from './CategoryReducer.js';
import PRODUCTREDUCER from './ProductReducer.js';
import ADSREDUCER from './AdsReducer.js';
import POSTREDUCER from './PostReducer.js';
import PARAMSREDUCER from './ParamsReducer.js';
import ORDERREDUCER from './Order.js';
export default combineReducers({
  CATEGORYREDUCER,
  PRODUCTREDUCER,
  ADSREDUCER,
  POSTREDUCER,
  PARAMSREDUCER,
  ORDERREDUCER,
});
