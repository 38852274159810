import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    flexGrow: 'nowrap',
    position: 'relative',
    // width: 320,
    top: 10,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 0,
      p: '0px',
    },
  },
  divWidth: {
    margin: '0 0px',

    width: '100%',
    [theme.breakpoints.up('md')]: {
      margin: '0 0px',
      width: ' ',
    },
  },

  itemcontainer: {
    display: 'flex',
    flexDirection: 'column',

    width: '90%',
  },

  paperstyle: {
    padding: 10,
    backgroundColor: 'whitesmoke',
  },
  separation: {
    mt: 1,
    mb: 1,
  },
  footer: {
    mt: 2,
    backgroundColor: 'whitesmoke',
  },

  root: {
    backgroundColor: 'black ',
    width: '100%',
    opacity: '1',

    marginTop: '40px',
    [theme.breakpoints.up('md')]: {
      marginTop: '15px',
    },
  },
  cardGrid: {
    display: 'flex',
  },
  moreButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '12px',
  },
  ButtonLowerCase: {
    textTransform: 'capitalize',
  },
  titleProduct: {
    textTransform: 'capitalize',
    fontWeight: 550,
    textAlign: 'center',
    mt: 1,
  },

  rootads: {
    position: 'fixed',

    backgroundColor: 'rgba(0,0,0,0)',
    top: '520px',
    marginRight: '18px',
    right: 0,
    marginRight: '0px',
    [theme.breakpoints.up('md')]: {
      top: '300px',
    },
  },

  // Set the text color to a deep gold
  text: {
    textTransform: 'capitalize',
    color: '#b28c51',
  },
  paper: {
    padding: 10,
    opacity: 0.6,
  },
  textTitleAds: {
    color: '#b28c51',
    textAlign: 'center',
  },
  title: {
    fontSize: '20px',
    textAlign: 'center',
    color: 'black',
    textTransform: 'uppercase',
  },
  titleFooter: {
    fontSize: '20px',
    textAlign: 'left',
    color: 'black',
    textTransform: 'uppercase',
  },
  content1: {
    padding: '20px',
    backgroundColor: 'white',
  },

  content2: {
    padding: '10px',

    backgroundColor: 'white',
  },
  boxTitle: {
    backgroundColor: 'white',

    opacity: '0.8',
    padding: '6px',
    marginBottom: '8px',
  },
  adsPaper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 7,
    marginTop: 4,
    p: 3,
    paddingTop: 8,
  },

  image: {
    width: '100%',
    height: 180,

    objectFit: 'cover',
  },
  name: {
    textAlign: 'center',
  },
  hide: {
    display: 'none',
  },
  price: {
    fontWeight: 'bold',
  },
  progress: {
    width: '100%',
    height: '10px',
    opacity: '0.7',
    [theme.breakpoints.up('md')]: {
      width: '600px',
    },
  },
  button: {
    backgroundColor: '#b28c51',
    width: '300px',
    height: '40px',
    '&:hover': {
      backgroundColor: 'whitesmoke',
      color: '#b28c51',
    },
    textTransform: 'capitalize',
  },
  upButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'fixed',
    width: '80px',
    right: 0,
    top: '700px',
    backgroundColor: 'white',
    opacity: '1',
  },
  link: {
    textDecoration: 'none',

    color: 'white',
    fontWeight: '450',
    '&:hover': {
      textDecoration: 'none',
      color: 'gray',
      textShadow: '0 1px 2px rgba(253, 208, 35, .3)',
      transition: 'color .3s',
    },
  },
  footerCenter: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',

    padding: 10,
    alignItems: 'baseline',
    marginLeft: '',
    [theme.breakpoints.up('md')]: {
      marginLeft: '50px',
      justifyContent: 'center',
    },
  },
  titleFolow: {
    color: '#b28c51',
    fontWeight: '450',
  },
  footerLink: {
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
    alignItems: 'baseline',
    marginLeft: '',
    [theme.breakpoints.up('md')]: {
      marginLeft: '50px',
    },
  },
  lastGrid: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  enjoy: {
    padding: 10,
  },

  imgIcone: {
    width: '30px',
    height: '30px',
  },
  follow: {
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'baseline',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      width: '200px',
      justifyContent: 'space-between',
      marginTop: '5px',
    },
  },
  poweredby: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: 'white',
  },
  slide2Grid: {
    backgroundColor: 'black',
    p: 1,
    marginBottom: '25px',
    marginTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      marginTop: '75px',
    },
  },
  textShadow: {
    marginLeft: '4px',
    color: 'rgb(253, 208, 35 )',
  },
  textShadow2: {
    textTransform: 'capitalize',
    fontWeight: 550,
    textAlign: 'center',
    color: 'rgb(253, 208, 35 )',

    mt: 1,
  },
  picIptvSlide: {
    backgroundColor: 'rgba(255,255,255,1)',
    padding: 1,

    marginBottom: '25px',

    marginTop: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'rgba(255,255,255,1)',
      padding: '1px',
      marginBottom: '25px',
      marginTop: '25px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  },
}));
