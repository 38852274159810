const initState = {
  adsTMP: [],
  loadingAds: false,
  adsError: '',
};
export default (ADSREDUCER = initState, action) => {
  switch (action.type) {
    case 'ALLADS':
      return {
        ...ADSREDUCER,
        adsTMP: action.payload,
        loadingAds: false,
        adsError: '',
      };

    case 'LOADINGADS':
      return {
        ...ADSREDUCER,
        adsError: '',
        loadingAds: true,
      };

    case 'ADSERROR':
      return {
        ...ADSREDUCER,
        loadingAds: false,
        adsError: action.payload,
      };

    default:
      return ADSREDUCER;
  }
};
