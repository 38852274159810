import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  getDocs,
} from 'firebase/firestore';
import { where, query } from '@firebase/firestore';

import { db } from '../Firebase/firebase';

export const getAds = () => async (dispatch) => {
  const ads = [];
  try {
    dispatch({ type: 'LOADINGADS' });
    const q = collection(db, 'ads');

    const data = await getDocs(q);
    data.forEach((doc) => {
      ads.push(doc.data());
    });
    dispatch({ type: 'ALLADS', payload: ads });
  } catch (error) {
    dispatch({ type: 'ADSERROR', payload: error });
  }
};
export const getParams = () => async (dispatch) => {
  const params = [];
  try {
    dispatch({ type: 'LOADINGPARAMS' });
    const q = collection(db, 'userInfo');

    const data = await getDocs(q);
    data.forEach((doc) => {
      params.push(doc.data());
    });
    dispatch({ type: 'ALLPARAMS', payload: params });
  } catch (error) {
    dispatch({ type: 'PARAMSERROR', payload: error });
  }
};
export const getPost = () => async (dispatch) => {
  const posts = [];
  try {
    dispatch({ type: 'LOADINGPOST' });
    const q = collection(db, 'post');

    const data = await getDocs(q);
    data.forEach((doc) => {
      posts.push(doc.data());
    });
    dispatch({ type: 'ALLPOST', payload: posts });
  } catch (error) {
    dispatch({ type: 'POSTERROR', payload: error });
  }
};

export const addCategory = (category) => (dispatch) => {
  dispatch({ type: 'ADDCATEGORY', payload: category });
};
export const filterCategory = (category) => async (dispatch) => {
  const catTmp = category?.toLowerCase().replace(/-/g, ' ');
  const cat = [];
  try {
    dispatch({ type: 'LOADING' });
    const q = query(
      collection(db, 'category'),
      where('category', '==', catTmp)
    );

    const data = await getDocs(q);
    data.forEach((doc) => {
      cat.push(doc.data());
    });

    dispatch({ type: 'FILTERCATEGORY', payload: cat });
  } catch (error) {}
};
export const getProductByCategory = (category) => async (dispatch) => {
  const catTmp = category?.toLowerCase().replace(/-/g, ' ');
  const cat = [];
  try {
    dispatch({ type: 'LOADINGPRODUCT' });
    const q = query(collection(db, 'product'), where('category', '==', catTmp));

    const data = await getDocs(q);
    data.forEach((doc) => {
      cat.push(doc.data());
    });

    dispatch({ type: 'FILTERPRODUCT', payload: cat });
  } catch (error) {
    dispatch({ type: 'PRODUCTERROR', payload: error });
  }
};
export const getProductById = (title) => async (dispatch) => {
  try {
    let product = [];
    dispatch({ type: 'LOADINGPRODUCT' });
    const q = query(
      collection(db, 'product'),
      where('title', '==', title?.trim().toLowerCase())
    );

    const data = await getDocs(q);

    data.forEach((doc) => {
      product.push(doc.data());
    });
    if (product?.length != 0)
      dispatch({ type: 'PRODUCTBYID', payload: product });
    else {
      product = { error: 'not found' };
      dispatch({ type: 'PRODUCTERROR', payload: product });
    }
  } catch (error) {
    dispatch({ type: 'PRODUCTERROR', payload: error });
  }
};
export const getPostByTitle = (title) => async (dispatch) => {
  const titleTmp = title?.trim()?.toLowerCase().replace(/-/g, ' ');
  try {
    let post = [];
    dispatch({ type: 'LOADINGPOST' });
    const q = query(collection(db, 'post'), where('title', '==', titleTmp));

    const data = await getDocs(q);

    data.forEach((doc) => {
      post.push(doc.data());
    });
    if (post?.length != 0) dispatch({ type: 'POSTBYTITLE', payload: post });
    else {
      post = { error: 'not found' };
      dispatch({ type: 'POSTERROR', payload: post });
    }
  } catch (error) {
    dispatch({ type: 'POSTERROR', payload: error });
  }
};
export const getAllProduct = (maxFetch) => async (dispatch) => {
  const product = [];
  try {
    dispatch({ type: 'LOADINGPRODUCT' });
    const q = collection(db, 'product');
    const qurry = query(q, where('ranking', '<=', `${maxFetch}`));

    const data = await getDocs(qurry);
    data.forEach((doc) => {
      product.push(doc.data());
    });

    dispatch({ type: 'ADDPRODUCT', payload: product });
  } catch (error) {
    dispatch({ type: 'PRODUCTERROR', payload: error });
  }
};
export const createOrder = (data) => async (dispatch) => {
  //------create order

  const orderCollection = collection(db, 'order');
  try {
    dispatch({ type: 'LOADINGORDER' });

    await addDoc(orderCollection, data);
    dispatch({ type: 'SETORDER' });
  } catch (error) {
    dispatch({ type: 'ERRORORDER', payload: error.message });
  }
};
export const createCheckout = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'LOADINGCOMMAND' });
    dispatch({ type: 'CHECKOUT', payload: data });
  } catch (error) {
    dispatch({ type: 'ERRORCOMMAND' });
  }
};
export const createCommande = (data) => async (dispatch) => {
  try {
    dispatch({ type: 'LOADINGCOMMAND' });
    dispatch({ type: 'CREATECOMMAND', payload: data });
  } catch (error) {
    dispatch({ type: 'ERRORCOMMAND' });
  }
};
export const resetCommande = () => async (dispatch) => {
  try {
    dispatch({ type: 'LOADINGCOMMAND' });
    dispatch({ type: 'RESETCOMMAND' });
  } catch (error) {
    dispatch({ type: 'ERRORCOMMAND', payload: error.message });
  }
};
export const getCommande = () => async (dispatch) => {
  try {
    dispatch({ type: 'GETCOMMAND' });
  } catch (error) {
    dispatch({ type: 'ERRORCOMMAND' });
  }
};
export const getCommandeCreated = () => async (dispatch) => {
  try {
    dispatch({ type: 'GETCOMMANDCREATED' });
  } catch (error) {
    dispatch({ type: 'ERRORCOMMAND' });
  }
};
