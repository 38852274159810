const initState = {
  productTMP: [],
  productFilter: [],
  productId: {},
  productLoad: false,
  productError: '',
};
export default (PRODUCTREDUCER = initState, action) => {
  switch (action.type) {
    case 'ADDPRODUCT':
      return {
        ...PRODUCTREDUCER,
        productTMP: action.payload,
        productLoad: false,
        productError: '',
      };
    case 'FILTERPRODUCT':
      return {
        ...PRODUCTREDUCER,
        productFilter: action.payload,
        productLoad: false,
        productError: '',
      };
    case 'PRODUCTBYID':
      return {
        ...PRODUCTREDUCER,
        productId: action.payload,
        productLoad: false,
        productError: '',
      };
    case 'LOADINGPRODUCT':
      return {
        ...PRODUCTREDUCER,
        productLoad: true,
        productError: '',
      };
    case 'PRODUCTERROR':
      return {
        ...PRODUCTREDUCER,
        productLoad: false,
        productError: action.payload,
      };

    default:
      return PRODUCTREDUCER;
  }
};
